<template>
  <div class="footer">
    <div class="w-100 text-center">
      <span>جميع الحقوق محفوظة</span>
      <sub class="copy">&copy;</sub>
      <span>{{ new Date().getFullYear() }}</span>
      <span>الاردنية الاولى للمزادات</span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.footer {
  border-top: 2px solid #f06b78;
  padding: 19px 15px 20px;
  margin: 0;
  right: 0;
  color: #98a6ad;
  background-color: #fff;
}

.copy {
  font-size: 25px;
}
</style>